export default {
  "page": "qNT",
  "container": "qNz",
  "form-container": "qNa",
  "preview-container": "qNH",
  "pdf-preview-container": "qNO",
  "content": "qNm",
  "header": "qNp",
  "close-button": "qNx btn btn--icon-only btn--tertiary btn--large"
};
