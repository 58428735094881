export default {
  "page": "qvp",
  "container": "qvx",
  "form-container": "qvw",
  "preview-container": "qvN",
  "pdf-preview-container": "qvv",
  "content": "qvo",
  "title": "qvn",
  "tabs-container": "qvi",
  "close-button": "qvA btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvY",
  "header": "qvh",
  "without-tabs": "qvf"
};
