export default {
  "page-wrapper": "qTN",
  "container": "qTv",
  "spinner-container": "qTo",
  "left-panel": "qTn",
  "form": "qTi",
  "left-content": "qTA",
  "form-footer": "qTY",
  "delete-button": "qTh",
  "header": "qTf",
  "preview-container": "qTK",
  "email-preview": "qTG",
  "close-button": "qTr btn btn--icon-only btn--tertiary btn--large"
};
