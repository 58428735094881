export default {
  "container": "qxb",
  "content": "qxy",
  "logos": "qxU",
  "logo": "qxj",
  "animation": "qxW",
  "title": "qxu title-2 mb-32",
  "avatar": "qwS",
  "dropdown": "qwc",
  "disabled": "qwq",
  "organization": "qwZ",
  "actions": "qwR"
};
