export default {
  "title": "qYD title-3",
  "personal-profile-container": "qYV",
  "personal-profile-section": "qYL",
  "member-info-title": "qYT",
  "kyc-complete-profile": "qYz",
  "info-fields": "qYa",
  "field": "qYH",
  "address-fields": "qYO",
  "field-span": "qYm",
  "input": "qYp",
  "cancel": "qYx"
};
