import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ProductsIndexRoute extends Route {
  @service store;
  @service sentry;
  @service intl;
  @service toastFlashMessages;
  @service networkManager;
  @service router;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    query: { refreshModel: true },
  };

  beforeModel(transition) {
    let { sortBy } = transition.to.queryParams;

    if (['-issue_date,-number', 'next_invoice_date,-customers.name'].includes(sortBy)) {
      this.router.replaceWith('products.index', {
        queryParams: {
          ...transition.to.queryParams,
          sortBy: 'title:asc',
        },
      });
    }
  }

  model(params) {
    this.fetchProductsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this._handleError(error));

    return {
      productsTask: this.fetchProductsTask,
    };
  }

  fetchProductsTask = restartableTask(async params => {
    let products = [];
    let { page, perPage, sortBy, query } = params;

    products = await this.store.query('product', {
      page: { number: page, size: perPage },
      sort_by: sortBy,
      query,
    });

    return {
      products,
      meta: {
        total_count: products?.meta?.total_count ?? 0,
        total_pages: products?.meta?.total_pages ?? 0,
        per_page: perPage,
        current_page: page,
      },
    };
  });

  resetController(controller) {
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = 'title:asc';
    controller.query = '';
  }

  _handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
