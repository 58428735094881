export default {
  "member-details": "qHI",
  "header": "qHt",
  "header-img": "qHM",
  "invited-persona": "qHP",
  "revoked-persona": "qHl",
  "invited-icon": "qHX",
  "revoked-icon": "qHC",
  "dropdown": "qHk",
  "header-body": "qHJ",
  "name": "qHs title-3",
  "email": "qHF caption-bold",
  "actions": "qHg body-2",
  "actions-cards": "qHD",
  "actions-transactions": "qHV",
  "body": "qHL",
  "expense-permissions-section": "qHT",
  "body-title": "qHz title-4",
  "body-attr": "qHa",
  "body-label": "qHH",
  "attr-buttons": "qHO",
  "body-role": "qHm small",
  "details-actions": "qHp",
  "actions-item": "qHx",
  "disclaimer": "qHw",
  "permissions": "qHN",
  "permission": "qHv",
  "permission-label": "qHo",
  "permission-check": "qHn",
  "permission-missing": "qHi",
  "editable-section": "qHA"
};
