export default {
  "container": "qNi",
  "form-container": "qNA",
  "title": "qNY",
  "title-border": "qNh",
  "tabs-container": "qNf",
  "preview-container": "qNK",
  "email-preview-container": "qNG",
  "close-button": "qNr btn btn--icon-only btn--tertiary btn--large"
};
