import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ALL_STAKEHOLDERS } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubApplicationsRoute extends Route {
  @service router;
  @service connectManager;
  @service intl;

  async model(params) {
    let { searchQuery, stakeholderSlug, page } = params;
    let integrations = [];

    if (!variation('feature--boolean-qontent-connect-hub')) {
      let allStakeholders = await this.connectManager.getAllStakeholders();
      let stakeholder = allStakeholders.find(({ slug }) => slug === stakeholderSlug);

      if (searchQuery) {
        integrations = await this.connectManager.searchIntegrationsTask
          .perform(params.searchQuery)
          .catch(ignoreCancelation);
      } else {
        integrations = await this.connectManager.getIntegrationsTask
          .perform({
            stakeholderId: stakeholder?.id,
            page,
            onlyMostPopularBanks: true,
          })
          .catch(ignoreCancelation);
      }

      return {
        integrations,
        allStakeholders,
      };
    }

    if (searchQuery) {
      integrations = await this.connectManager.searchIntegrationsTask
        .perform(params.searchQuery)
        .catch(ignoreCancelation);
    } else {
      integrations = await this.connectManager.getIntegrationsTask
        .perform({
          stakeholderSlug,
          page,
          onlyMostPopularBanks: true,
        })
        .catch(ignoreCancelation);
    }

    return {
      integrations,
      allStakeholders: ALL_STAKEHOLDERS(this.intl),
    };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setData(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.stakeholderSlug = undefined;
      controller.page = 1;
    }
  }
}
