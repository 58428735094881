export default {
  "dropdown-icon": "qKS",
  "dropdown": "qKc",
  "download-button": "qKq",
  "connections-banner": "qKZ",
  "recommendation-cards": "qKR",
  "wrapper-with-cards": "qKQ",
  "transactions-wrapper": "qKe",
  "separator": "qKB",
  "with-transaction": "qKE",
  "annual-billing-banner": "qKd",
  "mt-132": "qKI",
  "transactions-sidebar": "qKt",
  "empty-state-wrapper": "qKM",
  "wrapper-with-modular": "qKP",
  "modular-table-container": "qKl",
  "modular-table-sidebar": "qKX",
  "modular-table-no-overlay": "qKC",
  "sidebar-overlay": "qKk",
  "overlay-visible": "qKJ"
};
