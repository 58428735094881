import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { IS_CONTRACT_ACKNOWLEDGMENT_VIEWED } from 'qonto/constants/membership';
import { CFT } from 'qonto/constants/sentry-tags';
import { getSessionStorageItem } from 'qonto/utils/session-storage';

export default class ContractAcknowledgmentRoute extends Route {
  @service router;
  @service organizationManager;
  @service homePage;
  @service menu;
  @service segment;

  getRedirectRoute(transition) {
    return transition.to.queryParams.redirectRoute || this.homePage.defaultRouteName;
  }

  redirectToDefaultRoute() {
    this.router.replaceWith(this.homePage.defaultRouteName);
  }

  beforeModel() {
    if (getSessionStorageItem(IS_CONTRACT_ACKNOWLEDGMENT_VIEWED)) {
      return this.redirectToDefaultRoute();
    }

    let { membership } = this.organizationManager;

    if (!membership?.hasShowContractAcknowledgementModalFeature) {
      return this.redirectToDefaultRoute();
    }
  }

  model(params, transition) {
    let redirectRoute = this.getRedirectRoute(transition);
    let { membership } = this.organizationManager;

    return {
      redirectRoute,
      membership,
    };
  }

  activate() {
    this.menu.hide();

    Sentry.getCurrentScope().setTag('CFT', CFT.FRAUD_AND_COMPLIANCE);
    this.segment.track('overview_contractackscreen_loaded');
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
    this.menu.show();
  }
}
