export default {
  "header": "qDZ",
  "btn-preset": "qDR btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qDQ",
  "statements-table": "qDe",
  "no-result": "qDB",
  "lottie-illustration": "qDE",
  "wrapper": "qDd",
  "access-denied": "qDI",
  "access-denied-illustration": "qDt",
  "application-card": "qDM",
  "presets-wrapper": "qDP",
  "error-wrapper": "qDl",
  "error-container": "qDX",
  "error-illustration": "qDC mb-32",
  "invoicing-info": "qDk"
};
