export default {
  "multi-transfer": "qKT",
  "container": "qKz",
  "title": "qKa",
  "subtitle": "qKH",
  "form-wrapper": "qKO",
  "form": "qKm",
  "form-title": "qKp",
  "files-buttons": "qKx",
  "files-buttons-item": "qKw",
  "selected": "qKN",
  "ebics-button": "qKv",
  "divider": "qKo"
};
