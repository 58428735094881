export default {
  "container": "qaT",
  "page-wrapper": "qaz",
  "header": "qaa",
  "back-button-wrapper": "qaH",
  "header-inner": "qaO",
  "header-main-wrapper": "qam",
  "header-main": "qap",
  "header-right": "qax",
  "content-wrapper": "qaw",
  "content": "qaN",
  "content-body": "qav",
  "sidebar": "qao"
};
