export default {
  "body": "qpW",
  "members": "qpu",
  "members-results": "qxS",
  "members-list": "qxc",
  "members-title": "qxq caption-bold",
  "member": "qxZ",
  "empty-illustration": "qxR",
  "empty-title": "qxQ title-3",
  "empty-description": "qxe body-2",
  "invitable-members": "qxB",
  "details": "qxE",
  "pagination-footer": "qxd"
};
