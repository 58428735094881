import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierInvoicesExportIndexRoute extends Route {
  @service segment;
  @service bannerFlashMessages;

  beforeModel(transition) {
    let origin;
    switch (transition.from?.name) {
      case 'supplier-invoices.index':
        origin = 'supplier_invoice_page';
        break;
      case 'accounting-hub.details':
        origin = 'accounting_hub';
        break;
    }
    this.segment.track('supplier-invoices_export-modal_displayed', { origin });
  }

  setupController(controller) {
    super.setupController(controller);

    controller.selectedPeriod = controller.periodOptions[0];
    controller.format = controller.formatOptions[0]?.value || '';
    controller.separateFolders = false;
  }
}
