import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import dayjs from 'dayjs';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { getActivePopup } from 'qonto/utils/overview-promotional-popups';
import QontoInvoicingHomepage from 'qonto/utils/qonto-invoicing/qonto-invoicing-homepage';

export default class OverviewController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];

  @service router;
  @service flowLinkManager;
  @service abilities;
  @service segment;
  @service featuresManager;
  @service organizationManager;
  @service subscriptionManager;
  @service supplierInvoicesInsights;
  @service cashFlowManager;

  get hasContractBeenSignedLessThan90DaysAgo() {
    let { organization } = this.organizationManager;
    let { contractSignedAt } = organization;

    return dayjs().isBetween(contractSignedAt, dayjs(contractSignedAt).add(90, 'days'));
  }

  get isBefore6thJanuary2025() {
    return dayjs().isBefore(dayjs('2025-01-06'));
  }

  get hasAlreadySeenConnectExternalAccountsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`connect-external-accounts-popup-shown_${id}`);
  }

  get hasAlreadySeenMoveRecurringPaymentsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`move-recurring-payments-popup-shown_${id}`);
  }

  get externalBankRecommendationsName() {
    return this.model.recommendationsBundle?.bankName;
  }

  get hasRecommendations() {
    return this.model.recommendationsBundle?.recommendations.length > 0;
  }

  get qontoInvoicingHomepage() {
    return new QontoInvoicingHomepage(
      this.subscriptionManager,
      this.model.invoicesStatsTask,
      this.model.clientInvoicesOnboardingState,
      this.supplierInvoicesInsights
    );
  }

  get showQontoInvoicingActivationHomepage() {
    return this.qontoInvoicingHomepage.shouldShow;
  }

  get shouldShowAccountsFilter() {
    if (!this.subscriptionManager.isQontoInvoicing) {
      return true;
    }
    return (
      this.qontoInvoicingHomepage.isLogoStepDone !== true &&
      this.qontoInvoicingHomepage.hasReceivableInvoices !== true &&
      this.qontoInvoicingHomepage.hasSupplierInvoices !== true
    );
  }

  /**
   * Handles popup rendering.
   * Sort by priority.
   * Only one can be rendered per condition.
   * @returns {{reactComponent: () => React.ReactNode, props?: Record<any, any>}}}
   */
  get popup() {
    return getActivePopup({
      organization: this.organizationManager.organization,
      membership: this.organizationManager.membership,
      abilities: this.abilities,
      subscriptionManager: this.subscriptionManager,
      cashFlowManager: this.cashFlowManager,
    });
  }

  get createTransfer() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');
    let canCreateTransfer = this.abilities.can('create transfer');

    if (isQontoPilotFeatureEnabled) {
      if (canUseQontoPilot) {
        return false;
      } else {
        return canCreateTransfer && !canUseQontoPilot;
      }
    } else {
      return canCreateTransfer;
    }
  }

  @action
  redirectToTransferPage() {
    this.segment.track('overview_create_transfer_clicked');
    return this.router.transitionTo('transfers.new');
  }
}
