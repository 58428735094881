export default {
  "container": "qiJ",
  "wrapper": "qis",
  "grey-header-block": "qiF",
  "connect-app-header": "qig",
  "connect-app-header-link": "qiD",
  "connect-app-header-content": "qiV",
  "connect-app-header-content-default": "qiL",
  "connect-app-header-content-left": "qiT",
  "button-block": "qiz",
  "connect-app-body": "qia",
  "connect-app-body-left-content": "qiH",
  "connect-app-body-sidebar": "qiO"
};
