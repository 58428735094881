import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { CATEGORIES } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubFeaturedRoute extends Route {
  @service router;
  @service connectManager;

  async model(params) {
    let highlightedBanks = {};

    let featuredIntegrations = await this.connectManager.getFeaturedIntegrations();

    if (!variation('feature--boolean-qontent-connect-hub')) {
      let allCategories = await this.connectManager.getAllCategories();

      let category = allCategories.find(({ slug }) => slug === CATEGORIES.BANKS);

      if (params.searchQuery) {
        await this.connectManager.searchIntegrationsTask
          .perform(params.searchQuery)
          .catch(ignoreCancelation);
      }

      highlightedBanks = await this.connectManager.highlightBankIntegrationsTask
        .perform({
          categoryId: category?.id,
        })
        .catch(ignoreCancelation);

      highlightedBanks = {
        ...highlightedBanks,
        results: highlightedBanks?.results.slice(0, 6) ?? [],
      };

      return {
        featuredIntegrations,
        highlightedBanks,
      };
    }

    highlightedBanks = await this.connectManager.highlightPopularIntegrationsTask
      .perform({
        categorySlug: CATEGORIES.BANKS,
      })
      .catch(ignoreCancelation);

    return {
      featuredIntegrations: featuredIntegrations.connect_integrations || [],
      highlightedBanks: highlightedBanks?.connect_integrations?.slice(0, 6) ?? [],
    };
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.stakeholderSlug = undefined;
    }
  }
}
