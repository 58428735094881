import Route from '@ember/routing/route';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class ConfirmCompanyProfileRoute extends Route {
  activate() {
    Sentry.getCurrentScope().setTag('CFT', CFT.FRAUD_COMPLIANCE);
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
