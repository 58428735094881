import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubApplicationsController extends Controller {
  @service intl;
  @service router;
  @service segment;
  @service connectManager;

  @tracked searchQuery;
  @tracked stakeholderSlug;
  @tracked integrations = [];

  page = 1;
  hasMorePages = false;
  queryParams = ['searchQuery', 'stakeholderSlug'];

  get shouldDisplayIndex() {
    return this.router.currentRouteName === 'settings.connect-hub.applications.index';
  }

  get selectedStakeholder() {
    return this.model.allStakeholders.find(({ slug }) => slug === this.stakeholderSlug);
  }

  get pageTitle() {
    return this.intl.t('section-title.all-categories');
  }

  get searchResultsCount() {
    if ((this.searchQuery || this.stakeholderSlug) && this.integrations.length > 0) {
      if (this.searchQuery) {
        if (!variation('feature--boolean-qontent-connect-hub')) {
          return this.connectManager.searchIntegrationsTask.lastSuccessful?.value
            .total_results_size;
        }

        return this.connectManager.searchIntegrationsTask.lastSuccessful?.value.meta.total_count;
      }

      if (!variation('feature--boolean-qontent-connect-hub')) {
        return this.connectManager.getIntegrationsTask.lastSuccessful?.value.total_results_size;
      }

      return this.connectManager.getIntegrationsTask.lastSuccessful?.value.meta.total_count;
    }
    return 0;
  }

  setData(model) {
    if (variation('feature--boolean-qontent-connect-hub')) {
      this.integrations = model.integrations.connect_integrations;
      this.hasMorePages = model.integrations.meta.total_pages > this.page;
    } else {
      this.integrations = model.integrations.results;
      this.hasMorePages = model.integrations.total_pages > this.page;
    }
  }

  loadMoreTask = dropTask(async () => {
    if (!this.hasMorePages) return;

    this.page += 1;

    if (variation('feature--boolean-qontent-connect-hub')) {
      if (this.searchQuery) {
        let { connect_integrations, meta } = await this.connectManager.searchIntegrationsTask
          .perform(this.searchQuery, this.page)
          .catch(ignoreCancelation);

        this.hasMorePages = meta.total_pages > this.page;
        this.integrations = [...this.integrations, ...connect_integrations];
      } else {
        let { connect_integrations, meta } = await this.connectManager.getIntegrationsTask
          .perform({
            stakeholderSlug: this.stakeholderSlug,
            page: this.page,
            pageSize: this.pageSize,
            onlyMostPopularBanks: true,
          })
          .catch(ignoreCancelation);

        this.hasMorePages = meta.total_pages > this.page;
        this.integrations = [...this.integrations, ...connect_integrations];
      }
    } else {
      if (this.searchQuery) {
        let { total_pages: totalPages, results } = await this.connectManager.searchIntegrationsTask
          .perform(this.searchQuery, this.page)
          .catch(ignoreCancelation);

        this.hasMorePages = totalPages > this.page;
        this.integrations = [...this.integrations, ...results];
      } else {
        let { total_pages: totalPages, results } = await this.connectManager.getIntegrationsTask
          .perform({
            stakeholderId: this.model.stakeholder?.id,
            page: this.page,
            pageSize: this.pageSize,
            onlyMostPopularBanks: true,
          })
          .catch(ignoreCancelation);

        this.hasMorePages = totalPages > this.page;
        this.integrations = [...this.integrations, ...results];
      }
    }
  });

  @action onLoadMore() {
    this.loadMoreTask.linked().perform().catch(ignoreCancelation);
  }

  async queryFilters() {
    if (variation('feature--boolean-qontent-connect-hub')) {
      let { connect_integrations } = await this.connectManager.getIntegrationsTask
        .perform({
          categorySlug: this.model.categoryId,
          stakeholderSlug: this.stakeholderSlug,
        })
        .catch(ignoreCancelation);

      this.integrations = connect_integrations;
    } else {
      let { results } = await this.connectManager.getIntegrationsTask
        .perform({
          categoryId: this.model.categoryId,
          stakeholderId: this.selectedStakeholder?.id,
          onlyMostPopularBanks: true,
        })
        .catch(ignoreCancelation);

      this.integrations = results;
    }
  }

  onSearchTask = dropTask(async query => {
    this.searchQuery = query || undefined;
    this.page = 1;

    if (query) {
      let response = await this.connectManager.searchIntegrationsTask
        .perform(query, this.page)
        .catch(ignoreCancelation);

      this.integrations = variation('feature--boolean-qontent-connect-hub')
        ? response.connect_integrations
        : response.results;
    } else {
      this.queryFilters();
    }
  });

  @action
  handleStakeholderSelect(stakeholder) {
    let slug = stakeholder?.slug;

    this.stakeholderSlug = slug;
    this.queryFilters();

    if (slug) {
      this.segment.track('connect_filter', {
        filterName: 'DesignedFor',
        category: undefined,
        designedFor: this.stakeholderSlug,
      });
    }
  }

  @action
  handleFilterReset() {
    this.stakeholderSlug = undefined;
    this.page = 1;
    this.queryFilters();
  }
}
