export default {
  "page-header": "qKu",
  "header-breadcrumb": "qGS",
  "tabs-container": "qGc",
  "bottom-border": "qGq",
  "tabs-separator": "qGZ",
  "previous-request": "qGR",
  "body-link": "qGQ",
  "border-top": "qGe"
};
