export default {
  "container": "qxn",
  "content": "qxi",
  "logos": "qxA",
  "logo": "qxY",
  "animation": "qxh",
  "title": "qxf title-2 mb-32",
  "avatar": "qxK",
  "actions": "qxG mb-32",
  "footer": "qxr body-2"
};
