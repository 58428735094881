export default {
  "page-wrapper": "qfc",
  "content-wrapper": "qfq",
  "content": "qfZ",
  "period-field": "qfR",
  "format-options": "qfQ",
  "format-option": "qfe",
  "format-radio": "qfB",
  "format-description": "qfE body-2"
};
