export default {
  "page-container": "qmz",
  "form": "qma",
  "close-button": "qmH btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmO",
  "preview-padding": "qmm",
  "title": "qmp",
  "header": "qmx",
  "without-tabs": "qmw",
  "form-section": "qmN",
  "form-footer": "qmv",
  "disclaimer-text": "qmo"
};
