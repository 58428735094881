export default {
  "page": "qNE",
  "container": "qNd",
  "form-container": "qNI",
  "preview-container": "qNt",
  "pdf-preview-container": "qNM",
  "content": "qNP",
  "header": "qNl",
  "close-button": "qNX btn btn--icon-only btn--tertiary btn--large"
};
