export default {
  "page": "qwb",
  "container": "qwy",
  "form-container": "qwU",
  "preview-container": "qwj",
  "pdf-preview-container": "qwW",
  "content": "qwu",
  "header": "qNS",
  "close-button": "qNc btn btn--icon-only btn--tertiary btn--large"
};
