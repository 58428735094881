export default {
  "team": "qxI",
  "no-members": "qxt",
  "teams-header": "qxM",
  "header-search": "qxP",
  "search-bar": "qxl",
  "invite": "qxX body-2",
  "invite-text": "qxC",
  "empty-illustration": "qxk",
  "empty-title": "qxJ title-3",
  "empty-description": "qxs body-2",
  "pagination-footer": "qxF",
  "filter-search": "qxg",
  "fullsize": "qxD",
  "tabs-container": "qxV",
  "tabs-separator": "qxL",
  "tooltip-wrapper": "qxT",
  "invite-cta": "qxz",
  "kyc-disclaimer": "qxa",
  "kyc-disclaimer-width": "qxH"
};
