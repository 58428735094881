import Route from '@ember/routing/route';

import * as Sentry from '@sentry/ember';

export default class SupplierSubscriptionsRoute extends Route {
  // @service abilities;
  // @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'accounts-payable');

    // Proposition for the base route defining access
    // let canNavigate = this.abilities.can('navigate supplier-subscriptions');

    // if (!canNavigate || this.abilities.cannot('use supplier-subscriptions')) {
    //   return this.homePage.replaceWithDefaultPage();
    // }
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
