export default {
  "bank-accounts-show": "qDO",
  "header": "qDm l-app-content__page-header",
  "share-and-certificate": "qDp",
  "share": "qDx",
  "certificate": "qDw",
  "section-divider": "qDN",
  "content-wrapper": "qDv",
  "content": "qDo"
};
