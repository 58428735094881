export default {
  "page": "qoI",
  "container": "qot",
  "form-container": "qoM",
  "preview-container": "qoP",
  "pdf-preview-container": "qol",
  "content": "qoX",
  "title": "qoC",
  "tabs-container": "qok",
  "close-button": "qoJ btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qos",
  "header": "qoF",
  "without-tabs": "qog"
};
