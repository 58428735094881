export default {
  "supplier-invoices-page": "qfI",
  "supplier-invoices-page-header": "qft",
  "filters-container": "qfM",
  "search-bar": "qfP",
  "filters": "qfl",
  "bulk-actions": "qfX",
  "item-count": "qfC",
  "higher-index": "qfk",
  "title-wrapper": "qfJ",
  "title": "qfs",
  "subtitle": "qfF",
  "table-container": "qfg",
  "file-dropzone": "qfD",
  "dropzone-visible": "qfV",
  "header-actions": "qfL",
  "header-wrapper": "qfT",
  "disclaimer-wrapper": "qfz"
};
