export default {
  "guests": "qHY",
  "guests--upsell-screen": "qHh",
  "no-members": "qHf",
  "teams-header": "qHK",
  "empty": "qHG",
  "invite-text": "qHr",
  "body": "qHb",
  "members": "qHy",
  "members-list": "qHU",
  "members-title": "qHj caption-bold",
  "member": "qHW",
  "details": "qHu",
  "kyc-disclaimer": "qOS",
  "kyc-disclaimer-width": "qOc"
};
