export default {
  "page": "qvM",
  "container": "qvP",
  "form-container": "qvl",
  "preview-container": "qvX",
  "pdf-preview-container": "qvC",
  "content": "qvk",
  "title": "qvJ",
  "tabs-container": "qvs",
  "close-button": "qvF btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qvg",
  "header": "qvD",
  "without-tabs": "qvV"
};
