import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { BANK_CONNECTIONS_PAGE_SIZE, SOLUTION_INSTANCE_TYPES } from 'qonto/constants/connect';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ConnectionsRoute extends Route {
  @service abilities;
  @service connectManager;
  @service router;
  @service sentry;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    this.store.unloadAll('consentsBundle');
    this.store.unloadAll('bank-connection');
  }

  model() {
    let canViewAppsConnections = this.abilities.can('view oauth');
    let canViewBankConnections = this.abilities.can('view connections external-account');
    let canViewInvoicesConnections = this.abilities.can('view gmi-integration');

    let connections = {
      appsConnectionsTask: null,
      banksConnectionsTask: null,
      invoicesConnectionsTask: null,
    };

    if (canViewAppsConnections) {
      this.fetchAppsConnectionsTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

      connections.appsConnectionsTask = this.fetchAppsConnectionsTask;
    }

    if (canViewBankConnections) {
      this.fetchBanksConnectionsTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

      connections.banksConnectionsTask = this.fetchBanksConnectionsTask;
    }

    if (canViewInvoicesConnections) {
      this.fetchInvoicesConnectionsTask.perform().catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

      connections.invoicesConnectionsTask = this.fetchInvoicesConnectionsTask;
    }

    return {
      canViewAppsConnections,
      canViewBankConnections,
      canViewInvoicesConnections,
      connections,
    };
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  fetchAppsConnectionsTask = task(async () => await this.store.findAll('consentsBundle'));

  fetchBanksConnectionsTask = task(
    async () => await this.store.query('bank-connection', { per_page: BANK_CONNECTIONS_PAGE_SIZE })
  );

  fetchInvoicesConnectionsTask = task(async () => {
    let solutionInstances;

    if (variation('feature--bolean-gmi-async-flow')) {
      solutionInstances = await this.store.query('gmi-solution-instance', { status: 'enabled' });
    } else {
      solutionInstances = await this.store.query('solution-instance', {
        type: SOLUTION_INSTANCE_TYPES.GMI,
      });
    }

    try {
      let solutionIds = solutionInstances?.map(si => si.solutionId);
      let integrations =
        await this.connectManager.getIntegrationsBySolutionIdsTask.perform(solutionIds);

      solutionInstances.forEach(si => {
        let integration;

        if (variation('feature--boolean-qontent-connect-hub')) {
          integration = integrations.connect_integrations.find(
            i => i.tray_solution_id === si.solutionId
          );

          si.enrichWith({
            name: integration?.name,
            logoUrl: integration?.logo?.url,
          });
        } else {
          integration = integrations.results.find(i => i.data.tray_solution_id === si.solutionId);

          si.enrichWith({
            name: integration?.data.name,
            logoUrl: integration?.data.logo?.url,
          });
        }
      });
    } catch (error) {
      this.sentry.captureException(error, {
        captureContext: {
          extra: {
            description: `Error enriching solution instances with Prismic data`,
          },
        },
      });
    }

    return solutionInstances;
  });
}
