import Controller from '@ember/controller';

import { INTEGRATION_TYPES } from 'qonto/constants/connect';
import { ImageGallery } from 'qonto/react/components/connect/image-gallery';

export default class HubApplicationDetailsController extends Controller {
  imageGallery = ImageGallery;
  readMoreIsOpen = false;

  get isPartnership() {
    return this.model.integration.integration_type === INTEGRATION_TYPES.partnerships;
  }
}
