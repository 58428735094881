export default {
  "mandates": "qpm",
  "mandates-empty": "qpp",
  "header": "qpx",
  "header-empty": "qpw",
  "header-content": "qpN",
  "search": "qpv",
  "search-bar": "qpo",
  "body": "qpn",
  "isEmpty": "qpi",
  "left-section": "qpA",
  "mandates-list": "qpY",
  "mandate-suspended": "qph",
  "list-title": "qpf caption-bold",
  "list-empty": "qpK",
  "empty-illustration": "qpG",
  "empty-title": "qpr title-3",
  "empty-description": "qpb body-2",
  "body-details": "qpy",
  "l-app-content__page-header": "qpU",
  "pagination-footer": "qpj"
};
