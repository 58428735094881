import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class FreemiumUpgradeModalController extends Controller {
  @service intl;
  @service router;

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-account-receivable.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goBack() {
    this.router.transitionTo('freemium-upgrade');
  }
}
