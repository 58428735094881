export default {
  "bank-accounts": "qnV l-app-content__wrapper",
  "header-wrapper": "qnL",
  "scrolled-top": "qnT",
  "header": "qnz l-app-content__page-header",
  "create-account-button": "qna btn btn--primary",
  "main": "qnH",
  "accounts": "qnO",
  "subtitle-container": "qnm",
  "subtitle": "qnp title-3 mb-16",
  "amount": "qnx body-2",
  "list--cashbeeSavings": "qnw"
};
