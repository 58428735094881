export default {
  "page-container": "qmf",
  "form": "qmK",
  "close-button": "qmG btn btn--icon-only btn--tertiary btn--large",
  "preview": "qmr",
  "preview-padding": "qmb",
  "title": "qmy",
  "header": "qmU",
  "without-tabs": "qmj",
  "form-section": "qmW",
  "form-footer": "qmu",
  "disclaimer-text": "qpS"
};
