export default {
  "details": "qpP",
  "header": "qpl",
  "avatar": "qpX mr-16",
  "header-beneficiary": "qpC",
  "header-beneficiary-activity": "qpk",
  "header-beneficiary-details": "qpJ",
  "header-beneficiary-details-recurring": "qps",
  "infos": "qpF",
  "infos-title": "qpg",
  "infos-empty": "qpD",
  "infos-list": "qpV",
  "infos-list-item": "qpL",
  "infos-list-item-left": "qpT",
  "infos-list-item-right": "qpz",
  "infos-list-item-attachment": "qpa",
  "infos-transactions": "qpH",
  "actions": "qpO"
};
