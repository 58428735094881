import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ExternalAccountImportDataContext {
  outcome = null;

  constructor({ origin, bank_id, bank_name, bank_logo, bank_slug }) {
    this.origin = origin;
    this.bank_id = bank_id;
    this.bank_name = bank_name;
    this.bank_logo = bank_logo;
    this.bank_slug = bank_slug;
  }
}

export default class ExternalAccountImportFlowSetup extends FlowSetup {
  @service abilities;
  @service organizationManager;
  @service router;

  constructor() {
    super(...arguments);

    let {
      origin = DEFAULT_ORIGIN_URL,
      bank_id,
      bank_name,
      bank_logo,
      bank_slug,
    } = this.router.currentRoute.queryParams;

    this.dataContext = new ExternalAccountImportDataContext({
      origin,
      bank_id,
      bank_name,
      bank_logo,
      bank_slug,
    });
  }

  get isComingFromConnectHub() {
    return this.dataContext.origin?.includes('connect-hub');
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    if (this.abilities.cannot('import external-account')) {
      return this.router.replaceWith(this.dataContext.origin);
    }
  }

  @action
  onAbort() {
    if (this.isComingFromConnectHub) {
      this.router.transitionTo(this.dataContext.origin, this.dataContext.bank_slug);
    } else {
      this.router.transitionTo(this.dataContext.origin);
    }
  }

  @action
  onComplete() {
    if (this.isComingFromConnectHub) {
      let { organization } = this.organizationManager;

      this.router.transitionTo('accounts.index', organization);
    } else {
      this.router.transitionTo(this.dataContext.origin);
    }
  }
}
