export default {
  "signin": "qhA",
  "form": "qhY",
  "illustration": "qhh",
  "purple": "qhf",
  "mint": "qhK",
  "mustard": "qhG",
  "peach": "qhr",
  "form-wrapper": "qhb",
  "header-logo": "qhy",
  "links": "qhU",
  "no-account-label": "qhj",
  "spinner-container": "qhW",
  "main-image-container": "qhu",
  "main-image": "qfS"
};
