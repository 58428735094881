export default {
  "wrapper": "qAA",
  "integrations": "qAY",
  "description": "qAh body-2",
  "api": "qAf flex flex-column",
  "api-container": "qAK flex large",
  "api-input": "qAG large",
  "api-btn": "qAr",
  "key-input": "qAb"
};
