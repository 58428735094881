import EmberRouter from '@embroider/router';

import config from './config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

// eslint-disable-next-line array-callback-return
Router.map(function () {
  this.route('404', { path: '/*path' });
  this.route('maintenance');
  this.route('deeplinks');
  this.route('mobile-only');
  this.route('desktop-only');

  this.route('signin');
  this.route('logout');

  this.route('gmi-setup');

  this.route('passwords', function () {
    this.route('forgot');
    this.route('reset');
  });

  this.route('users', function () {
    this.route('email-verification', { path: 'email-verification/:verification_code' });
  });

  this.route('invitations', { resetNamespace: true }, function () {
    this.route('show', { path: '/:token' }, function () {
      this.route('accountant');
      this.route('credentials');
      this.route('member', function () {
        this.route('info');
        this.route('address');
        this.route('success');
        this.route('kyc', function () {
          this.route('select');
          this.route('upload');
          this.route('details');
        });
      });
    });
  });

  this.route(
    'confirm-company-profile',
    { path: '/confirm-company-profile/:confirmation_token' },
    function () {
      /**
       * ember layout system is broken with error substates,
       * unless we create an empty route callback or explicitly set an index route
       */
    }
  );

  this.route('oauth', function () {
    this.route('consent');
  });

  this.route('protected', { path: '/' }, function () {
    this.route('zendesk', { resetNamespace: true }, function () {
      this.route('sso');
    });

    this.route('accounting-hub', { resetNamespace: true }, function () {
      this.route('details', { path: ':organization_slug' });
    });

    this.route('activate', { resetNamespace: true });

    this.route('onboarding', { resetNamespace: true }, function () {
      this.route('cards', { path: ':organization_id/cards' });
      this.route('topup', { path: ':organization_id/topup' }, function () {
        this.route('amount');
        this.route('payment');
        this.route('success');
        this.route('completed');
        this.route('fail');
      });
    });

    this.route(
      'organizations',
      { path: 'organizations/:organization_slug', resetNamespace: true },
      function () {
        this.route('settings', { resetNamespace: true }, function () {
          this.route('personal', function () {
            this.route('profile');
            this.route('notifications');
            this.route('language');
            this.route('security');
            this.route('appearance');
          });
          this.route('price-plan', function () {
            this.route('details');
            this.route('invoices');
            this.route('discounts');
            this.route('payment-methods');
          });

          this.route('user-profile');
          this.route('update-phone-number');
          this.route('notifications');
          this.route('localization');
          this.route('security');
          this.route('appearance');
          this.route('organization-profile');
          this.route('company-profile', function () {
            this.route('edit-organization', { path: 'edit' });
            this.route('edit-membership', { path: 'edit/:membership_id' });
            this.route('add-membership', { path: 'add' });
          });
          this.route('historical-data');
          this.route('subscription', function () {
            this.route('invoices');
            this.route('promo-codes');
          });
          this.route('analytics');
          this.route('referrals', function () {
            this.route('index', { path: '/' });
            this.route('show');
          });
          this.route('insurances');
          this.route('integrations');
          this.route('connections', function () {
            this.route('apps');
            this.route('banks');
            this.route('invoices');
          });
          this.route('approval-workflows');
          this.route('receipts-forward', function () {
            this.route('index', { path: '/' });
            this.route('upgrade-plan');
          });
          this.route('einvoicing');
          this.route('receipts-reminder', function () {
            this.route('index', { path: '/' });
          });
          this.route('connect-hub', function () {
            this.route('featured');
            this.route('categories', { path: '/categories/:category_slug' });
            this.route('collections', { path: '/collections/:collection_slug' });
            this.route('applications', function () {
              this.route('index', { path: '/' });
              this.route('hub-application', { path: '/:application_slug' }, function () {
                this.route('details', { path: '/' }, function () {
                  this.route('about');
                  this.route('description');
                  this.route('install');
                  this.route('settings');
                });
                this.route('authentication');
                this.route('setup');
                this.route('hris-success');
              });
            });
          });
        });

        this.route('landing');

        this.route('show', { path: '/' }, function () {
          this.route('beneficiaries', { resetNamespace: true }, function () {
            this.route('multi', function () {
              this.route('new');
              this.route('review', { path: '/:multi_beneficiary_id/review' }, function () {
                this.route('confirm');
              });
            });
          });

          this.route('capital', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('company-verification');
            this.route('status');
            this.route('shareholders', function () {
              this.route('index', { path: '/' });
              this.route('owner', { path: '/owner/:id' }, function () {
                this.route('choose-type');
                this.route('legal');
                this.route('physical');
              });
              this.route('physical-or-legal');
              this.route('legal-entities', { path: '/legal' }, function () {
                this.route('new');
              });
              this.route('stakeholders', function () {
                this.route('new');
                this.route('edit', { path: '/:id' });
              });
            });
            this.route('by-laws');
            this.route('choose-poa-type');
            this.route('address');
            this.route('review');
            this.route('deposit');
            this.route('reviewed', function () {
              this.route('contract');
            });
            this.route('certificate');
            this.route('kbis-info');
            this.route('upload');
            this.route('release', function () {
              this.route('contract');
            });
            this.route('finish');
          });

          this.route('checks', { resetNamespace: true }, function () {
            this.route('new');
            this.route('mandate-settings');
          });

          this.route('receipts-forward', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('upgrade-plan');
          });

          this.route('members', { resetNamespace: true }, function () {
            this.route('list', function () {
              this.route('member', { path: '/:membership_id' });
            });
            this.route('upgrade-plan');

            // Tabs
            this.route('active', function () {
              this.route('member', { path: '/:membership_id' });
            });
            this.route('inactive', function () {
              this.route('member', { path: '/:membership_id' });
            });
            this.route('to-invite', function () {
              this.route('member', { path: '/:membership_id' });
            });

            // Deeplinks
            this.route('show');
          });

          this.route('guests', { resetNamespace: true }, function () {
            this.route('guest', { path: '/:membership_id' });
            this.route('upgrade-plan');

            // Deeplinks
            this.route('show');
          });

          this.route('requests', { resetNamespace: true }, function () {
            this.route('upgrade-plan');
            this.route('landing');
            this.route('pending', function () {
              this.route('index', { path: '/' });
              this.route('multi-transfer-detail', {
                path: '/multi-transfer-detail/:request_id',
              });
              this.route('requester-multi-transfer-detail', {
                path: '/requester-multi-transfer-detail/:request_id',
              });
            });
            this.route('past', function () {
              this.route('index', { path: '/' });
              this.route('multi-transfer-detail', {
                path: '/multi-transfer-detail/:request_id',
              });
            });
            this.route('transfers', function () {
              this.route('new');
              this.route('multi', function () {
                this.route('new');
                this.route('review', function () {
                  this.route('confirm');
                });
              });
            });
            this.route('cards', function () {
              this.route('new', function () {
                this.route('virtual');
                this.route('flash');
              });
            });
          });

          this.route('tasks', { resetNamespace: true }, function () {
            this.route('pending', function () {
              this.route('index', { path: '/' });
              this.route('multi-transfer-detail', {
                path: '/multi-transfer-detail/:request_id',
              });
              this.route('multi-direct-debit-collection', {
                path: '/multi-direct-debit-collection/:multi_request_id',
              });
            });
            this.route('past', function () {
              this.route('index', { path: '/' });
              this.route('multi-transfer-detail', {
                path: '/multi-transfer-detail/:request_id',
              });
              this.route('multi-direct-debit-collection', {
                path: '/multi-direct-debit-collection/:multi_request_id',
              });
            });
          });

          this.route('reimbursements', { resetNamespace: true }, function () {
            this.route('requests');
            this.route('pending', function () {
              this.route('to-approve');
              this.route('to-pay');
            });
            this.route('completed');
          });

          this.route('budgets', { resetNamespace: true }, function () {
            this.route('list', { path: '/' });
            this.route('show', { path: '/:budget_id' });
            this.route('upgrade-plan');
          });

          this.route('forecast', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
          });

          this.route('upcoming-transactions', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
          });

          this.route('cash-flow-categories', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('type', { path: '/:type' }, function () {
              this.route('categories', function () {
                this.route('new');
                this.route('category', { path: '/:category_id' }, function () {
                  this.route('new');
                });
              });
            });
          });

          this.route('feature-discovery', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('interest');
            this.route('success');
          });

          this.route('transactions', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('show', { path: '/:transaction_id' });

            // Deeplinks
            this.route('missing-attachments');
            this.route('suggested-attachments');
          });

          this.route('overview', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('new');
          });

          this.route('freemium-upgrade', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('modal');
          });

          this.route('transfers', { resetNamespace: true }, function () {
            this.route('new');
            this.route('internal', function () {
              this.route('new');
              this.route('edit', { path: '/:transfer_id' });
            });
            this.route('pending');
            this.route('past');
            this.route('requests', function () {
              this.route('multi-transfer', {
                path: '/multi-transfer/:request_id',
              });
              this.route('requester-multi-transfer', {
                path: '/requester-multi-transfer/:request_id',
              });
            });
            this.route('landing');
            this.route('beneficiaries');
            this.route('multi', function () {
              this.route('new');
              this.route('review', { path: '/:multi_transfer_id/review' }, function () {
                this.route('confirm');
              });
            });
          });

          this.route('direct-debit-collections', { resetNamespace: true }, function () {
            this.route('requests', function () {
              this.route('request', {
                path: '/request/:multi_request_id',
              });
            });
            this.route('processing', function () {
              this.route('payment-link', { path: `payment-link/:id` });
            });
            this.route('completed');
            this.route('increase-collection-limit');
          });

          this.route('mandates', { resetNamespace: true }, function () {
            this.route('list', { path: '/' }, function () {
              this.route('mandate', { path: '/:mandate_id' }, function () {
                this.route('edit');
              });
            });
          });

          this.route('cards', { resetNamespace: true }, function () {
            this.route('physical-or-virtual');
            this.route('setpin', { path: '/:card_id/set_pin' });

            this.route('requests');
            this.route('my-cards');
            this.route('team');
            this.route('archived');

            this.route('settings', { path: '/settings/:id' }, function () {
              this.route('restrictions');
              this.route('nickname');
              this.route('options');
              this.route('payment-limits');
              this.route('payment-lifespan-limit');
              this.route('withdrawal-limits');
            });

            this.route('concierge-and-lounge');

            this.route('index', { path: '/' });

            // Deeplinks
            this.route('show');
          });

          this.route('card-acquirer', { resetNamespace: true }, function () {
            this.route('payments');
          });

          this.route('bank-accounts', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('details', { path: '/:slug' });
            this.route('new');
          });

          this.route('accounts', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('details', { path: '/:slug' });
            this.route('new', function () {
              this.route('index', { path: '/' });
              this.route('current');
              this.route('savings');
            });
            this.route('statements');
          });

          this.route('savings', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
          });

          this.route('counterparties', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('upgrade-plan');
          });

          this.route('f24', { resetNamespace: true }, function () {
            this.route('show', { path: '/:id' });
            this.route('list', { path: '/' }, function () {
              this.route('upcoming', { path: '/' });
              this.route('past');
            });
            this.route('create', function () {
              this.route('index', { path: '/' });
            });
          });

          this.route('pagopa', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
          });

          // IT einvoicing
          this.route('einvoice-activation', { resetNamespace: true });

          // FR einvoicing
          // TO DO: restore this route when we work on PDP onboarding
          // this.route('einvoicing-consent', { resetNamespace: true });

          this.route('invoicing-settings', { resetNamespace: true });

          this.route('receivable-invoices', { resetNamespace: true }, function () {
            this.route('duplicate', { path: '/:id/duplicate' }, function () {
              this.route('products');
              this.route('payment');
            });
            this.route('index', { path: '/' });
            this.route('credit-note-show', { path: '/credit-note/:id' });
            this.route('show', { path: '/:id' });
            this.route('new', function () {
              this.route('products');
              this.route('payment');
            });
            this.route('edit', { path: '/:id/edit' }, function () {
              this.route('index', { path: '/' });
              this.route('products');
              this.route('payment');
            });
            this.route('share', { path: '/:id/share' });
            this.route('credit-notes', function () {
              this.route('new');
              this.route('share', { path: '/:id/share' });
            });
            this.route('export', function () {
              this.route('success');
            });
            this.route('addon');
            this.route('accountant-access-upgrade');
            this.route('payment-links-upgrade');
          });

          this.route('invoice-subscriptions', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('edit', { path: '/:id/edit' });
            this.route('sdd', { path: '/:id/sdd' }, function () {
              this.route('setup');
              this.route('payment-link');
            });
          });

          this.route('payment-links', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
          });

          this.route('quotes', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('show', { path: '/:id' });
            this.route('new', function () {
              this.route('index', { path: '/' });
              this.route('products');
            });
            this.route('edit', { path: '/:id/edit' }, function () {
              this.route('index', { path: '/' });
              this.route('products');
            });
            this.route('duplicate', { path: '/:id/duplicate' }, function () {
              this.route('index', { path: '/' });
              this.route('products');
            });
            this.route('share', { path: '/:id/share' });
          });

          this.route('products', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('edit', { path: '/:id/edit' });
          });

          this.route('clients', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('client', { path: '/:id' }, function () {
              this.route('edit');
              this.route('reminders-configuration');
            });
          });

          this.route('supplier-invoices', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('intro');
            this.route('freemium-upgrade');
            this.route('list', function () {
              this.route('match-transaction');
            });
            this.route('match-transactions', { path: '/:invoice_id/match-transactions' });
            this.route('show', { path: '/:invoice_id' });
            this.route('credit-notes-preview', { path: '/:invoice_id/credit-notes-preview/:type' });
            this.route('export', function () {
              this.route('success');
            });
          });

          this.route('suppliers', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('new');
            this.route('edit', { path: '/:id/edit' });
          });

          this.route('self-billing', { resetNamespace: true }, function () {
            this.route('new');
            this.route('show', { path: '/:id' });
          });

          this.route('custom-labels', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('manage');
            this.route('upgrade-plan');
          });

          this.route('financing', { resetNamespace: true }, function () {
            this.route('offer', { path: '/:uid' });
            this.route('partners', function () {
              this.route('offer', { path: '/:uid' });
            });
            this.route('pay-later', function () {
              this.route('index', { path: '/' });
              this.route('in-progress');
              this.route('completed');
            });
          });

          this.route('flex-kyb', { resetNamespace: true });

          this.route('flows', { path: '/flows/:name/:step_id', resetNamespace: true });

          this.route('teams', { resetNamespace: true }, function () {
            this.route('index', { path: '/' });
            this.route('upgrade-plan');
          });

          this.route('upload-periodic-update-documents', { resetNamespace: true });
          this.route('upload-power-of-attorney', { resetNamespace: true });

          this.route('kyc', { resetNamespace: true }, function () {
            this.route('intro');
            this.route('start');
            this.route('fail');
          });

          this.route('periodic-review-warning', { resetNamespace: true });
          this.route('contract-acknowledgment', { resetNamespace: true });

          this.route('agencia-tributaria', { resetNamespace: true }, function () {
            this.route('list', { path: '/' }, function () {
              this.route('processing', { path: '/' });
              this.route('completed');
            });
          });

          this.route('riba', { resetNamespace: true }, function () {
            this.route('tasks', { path: '/' });
            this.route('processing');
            this.route('completed');
          });

          this.route('insurance-hub', { path: '/insurance', resetNamespace: true }, function () {
            this.route('policies');
            this.route('categories', { path: '/categories/:category_slug' });
            this.route('insurances', { path: '/' }, function () {
              this.route('index', { path: '/' });
              this.route('product', { path: '/:uid' });
            });
          });

          this.route('product-discovery-redirect', { resetNamespace: true });
        });
      }
    );

    this.route('protected-deeplinks', { resetNamespace: true });
  });
});
