import Controller from '@ember/controller';

import { SupplierSubscriptions } from 'qonto/react/accounts-payable/components/supplier-subscriptions';

export default class SupplierSubscriptionsListController extends Controller {
  supplierSubscriptions = SupplierSubscriptions;
  // This file will house the code to check for upsell and free-trial availability and ensure the appropriate empty state screen is displayed.

  get displayEmptyState() {
    return false;
  }
}
