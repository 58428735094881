import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class SupplierInvoicesFreemiumUpgradeController extends Controller {
  @service intl;
  @service router;

  get lottieProps() {
    return {
      relativePath: '/freemium-upgrade/upsell-account-payable.json',
      locale: this.intl.primaryLocale,
    };
  }

  @action
  goToSupplierInvoicesList() {
    this.router.transitionTo('supplier-invoices.list');
  }
}
