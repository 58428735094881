export default {
  "page-wrapper": "qDJ",
  "container": "qDs",
  "sidebar": "qDF",
  "search-input": "qDg",
  "organizations-list": "qDD",
  "content": "qDV",
  "no-result": "qDL",
  "lottie-illustration": "qDT",
  "accounting-header": "qDz",
  "logo": "qDa",
  "accounting-title": "qDH"
};
