export default {
  "accounts": "qDK",
  "header-subtitle": "qDG title-4",
  "subtitle-container": "qDr",
  "subtitle": "qDb title-3 mb-16",
  "amount": "qDy body-2",
  "recommendations-section": "qDU",
  "dismissing": "qDj",
  "recommendations-divider": "qDW",
  "list--cashbeeSavings": "qDu",
  "list": "qVS",
  "icon": "qVc",
  "badge": "qVq",
  "discover-tile": "qVZ"
};
