import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class ConnectHubHubApplicationRoute extends Route {
  @service connectManager;
  @service organizationManager;
  @service store;

  rootRouteName = 'settings.connect-hub.applications';

  async model(params) {
    let applicationSlug = params.application_slug;
    let { organization } = this.organizationManager;
    let parentModel = this.modelFor(this.rootRouteName);
    let lowerCaseApplicationSlug = applicationSlug.toLowerCase();
    let currentIntegration;

    currentIntegration =
      parentModel?.allIntegrations?.find(
        integration => integration.uid === lowerCaseApplicationSlug
      ) || (await this.connectManager.getIntegration(lowerCaseApplicationSlug));

    if (!currentIntegration) {
      this.replaceWith('/404');
    }

    let applications = await this.store.query('hub-application', {
      organization_id: organization.id,
      filters: { slug: applicationSlug },
    });

    return {
      hubApplication: applications[0],
      organization,
      integration: variation('feature--boolean-qontent-connect-hub')
        ? currentIntegration.connect_integration
        : currentIntegration?.data,
    };
  }
}
