import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class UploadPeriodicUpdateDocumentsRoute extends Route {
  @service menu;
  @service organizationManager;
  @service store;
  @service router;

  async model(params, transition) {
    let documentCollectionProcesses = await this.store.query('document-collection-process', {
      filter: {
        subject_type: 'Organization',
        subject_id: this.organizationManager.organization.id,
        status: 'created,pending_upload',
      },
      include: 'required_documents',
    });

    return {
      fromRouteName: transition.from?.name,
      documentCollectionProcess: documentCollectionProcesses.find(
        dcp => dcp.reason === 'kyc_kyb_periodic_update_process'
      ),
    };
  }

  afterModel(model) {
    if (!model.documentCollectionProcess?.id) {
      this.router.transitionTo('overview.index');
    }
  }

  activate() {
    Sentry.getCurrentScope().setTag('CFT', CFT.FRAUD_AND_COMPLIANCE);
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
