import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

const ALLOWED_STATUSES = ['waiting', 'rejected'];
import { CFT, PIPE } from 'qonto/constants/sentry-tags';

export default class FlexKybRoute extends Route {
  @service router;
  @service menu;
  @service organizationManager;

  beforeModel() {
    let { flexKyb, isDeFreelancer } = this.organizationManager.organization;

    if (!isDeFreelancer || !ALLOWED_STATUSES.includes(flexKyb?.status)) {
      this.router.replaceWith('organizations.show');
    }
  }

  model(params, transition) {
    return {
      fromRouteName: transition.from?.name,
    };
  }

  activate() {
    this.menu.hide();
    Sentry.getCurrentScope().setTag('CFT', CFT.CUSTOMER_ENGAGEMENT);
    Sentry.getCurrentScope().setTag('PIPE', PIPE.CUSTOMER_ENGAGEMENT.KYCB);
  }

  deactivate() {
    this.menu.show();
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
    Sentry.getCurrentScope().setTag('PIPE', undefined);
  }
}
