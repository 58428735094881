export default {
  "container": "qOn",
  "caption": "qOi caption",
  "compact-header": "qOA",
  "visible": "qOY",
  "page-container": "qOh",
  "main": "qOf",
  "tabs": "qOK",
  "tabs-nav": "qOG",
  "tabs-panels": "qOr",
  "tabs-panel": "qOb body-2",
  "sidebar": "qOy",
  "cms-content": "qOU"
};
