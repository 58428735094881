export default {
  "mt-16": "qnh",
  "title": "qnf",
  "avatar": "qnK",
  "edit-organization-form": "qnG",
  "dropdown-content": "qnr",
  "city-block": "qnb",
  "zip-code-input": "qny",
  "cancel-button": "qnU",
  "error-message": "qnj",
  "nature-of-operations": "qnW",
  "error": "qnu",
  "tax-country": "qiS",
  "tooltip": "qic",
  "legal-code": "qiq",
  "activity-type": "qiZ"
};
