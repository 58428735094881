export default {
  "container": "qht",
  "header-cell": "qhM caption-bold",
  "col-8": "qhP",
  "table": "qhl",
  "cell": "qhX body-2",
  "cell-content": "qhC",
  "row": "qhk",
  "empty": "qhJ",
  "quick-actions": "qhs",
  "card-infos": "qhF",
  "card-digits": "qhg",
  "empty-state": "qhD"
};
