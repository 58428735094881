export default {
  "vat-number": "qYS body-2",
  "banner": "qYc",
  "container": "qYq",
  "organization-profile": "qYZ",
  "input": "qYR",
  "medium-input": "qYQ medium",
  "small-input": "qYe small",
  "actions": "qYB",
  "company-info": "qYE",
  "company-info-section": "qYd",
  "company-address-section": "qYI",
  "company-country": "qYt",
  "company-billing-email": "qYM",
  "company-address": "qYP"
};
