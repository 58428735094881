import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { labelOverTimeAggregations, monthlyAggregation } from 'qonto/constants/cash-flow';
import { apiBaseURL } from 'qonto/constants/hosts';
import { FrequencySelector } from 'qonto/react/components/cash-flow/components/frequency-selector';
import { OverviewBoxes } from 'qonto/react/components/cash-flow/components/overview-boxes';
import { ProjectedForecastToggle } from 'qonto/react/components/cash-flow/components/projected-forecast-toggle';
import { TablesLayout } from 'qonto/react/components/cash-flow/components/tables-layout';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import buildQuery from 'qonto/utils/statistics';

const MAX_PERIOD_LENGTH = 12;
const DISPLAYED_MONTHS_CASHFLOW_CATEGORIES = 4;
export const CHART_DISPLAYED_MONTHS_CASHFLOW = 5;

export const COLUMNS_BY_BREAKPOINT = {
  isForecastXs: 6,
  isForecastSm: 8,
  isForecastMd: 10,
  isForecastLg: 12,
  isForecastXl: 14,
};

export default class ForecastIndexController extends Controller {
  tablesLayout = TablesLayout;
  overviewBoxes = OverviewBoxes;
  FrequencySelector = FrequencySelector;
  ProjectedForecastToggle = ProjectedForecastToggle;

  @service abilities;
  @service intl;
  @service store;
  @service organizationManager;
  @service cashFlowTimeseriesManager;
  @service cashFlowManager;
  @service sentry;
  @service segment;
  @service toastFlashMessages;
  @service networkManager;
  @service media;

  @reads('organizationManager.organization.id') organizationId;

  @tracked indexOffset = 1; // take into account the extra month before createdAt
  @tracked currentDateIndex = 0;
  @tracked selectedPeriod;
  @tracked _selectedFrequency = 'monthly';
  @tracked chartStatistics = [];
  @tracked chartComparisonStatistics = [];
  @tracked maxIndex = MAX_PERIOD_LENGTH - 1;
  @tracked focusIndex = this.numberOfColumns - 1; // Default focus to current period
  @tracked chartIndexOffset = 0;
  @tracked silentFetch = false;
  @tracked showProjectedForecast = false;

  @tracked hoveredColumnIndex;

  @action onTableCellMouseEnter(index) {
    this.hoveredColumnIndex = index;
    this._updateChartHighlight(index);
  }

  @action onTableCellMouseLeave() {
    this.hoveredColumnIndex = undefined;
    this._clearChartHighlight();
  }

  @action redrawChart() {
    // TODO: this retriggers a chart redraw - it's a workaround until the chart in Emer is deprecated / refactored
    // eslint-disable-next-line no-self-assign
    this.chartIndexOffset = this.chartIndexOffset;
  }

  _lastHighlightedIndex = null;

  _updateChartHighlight(index) {
    // Skip if highlighting the same column again
    if (this._lastHighlightedIndex === index) return;

    // Use a more direct selector to target all elements with this column index
    let targetElements = document.querySelectorAll(`[data-column-index="${index}"]`);
    if (!targetElements.length) return;

    // If we had a previous highlight (and it's not the current date), remove it
    if (
      this._lastHighlightedIndex !== null &&
      this._lastHighlightedIndex !== this.currentDateRelativeIndex
    ) {
      let previousElements = document.querySelectorAll(
        `[data-column-index="${this._lastHighlightedIndex}"]`
      );
      previousElements.forEach(el => {
        if (el.classList.contains('cashflow-month-container-current')) {
          el.classList.remove('cashflow-month-container-current');
        }
      });
    }

    // Add highlight class to the new elements
    targetElements.forEach(el => {
      if (!el.classList.contains('cashflow-month-container-current')) {
        el.classList.add('cashflow-month-container-current');
      }
    });

    // Remember this index for next time
    this._lastHighlightedIndex = index;
  }

  _clearChartHighlight() {
    // Only clear if we have something to clear and it's not the current date
    if (
      this._lastHighlightedIndex !== null &&
      this._lastHighlightedIndex !== this.currentDateRelativeIndex
    ) {
      let elementsToReset = document.querySelectorAll(
        `[data-column-index="${this._lastHighlightedIndex}"]`
      );
      elementsToReset.forEach(el => {
        if (el.classList.contains('cashflow-month-container-current')) {
          el.classList.remove('cashflow-month-container-current');
        }
      });
      this._lastHighlightedIndex = null;
    }
  }

  get displayedMonths() {
    return this.numberOfColumns;
  }

  get chartDisplayedMonths() {
    return this.numberOfColumns + 1;
  }

  get numberOfColumns() {
    let matchingBreakpoint = Object.keys(COLUMNS_BY_BREAKPOINT).find(
      breakpoint => this.media[breakpoint]
    );

    return matchingBreakpoint
      ? COLUMNS_BY_BREAKPOINT[matchingBreakpoint]
      : DISPLAYED_MONTHS_CASHFLOW_CATEGORIES;
  }

  get selectedFrequency() {
    return this._selectedFrequency;
  }

  get bankAccounts() {
    return this.model.bankAccounts;
  }

  get adapter() {
    return this.store.adapterFor('transaction');
  }

  get isChartLoading() {
    return this.getStatisticsTask.isRunning;
  }

  get isTimeseriesLoading() {
    return this.cashFlowTimeseriesManager.fetchTimeseriesTask.isRunning && !this.silentFetch;
  }

  get isTimeseriesError() {
    return this.cashFlowTimeseriesManager.fetchTimeseriesTask.last?.isError;
  }

  get isChartError() {
    return this.getStatisticsTask.last?.isError;
  }

  get isTableLoading() {
    return (
      this.getLabelCashflowStatisticsTask.isRunning ||
      this.getUnlabeledCashflowStatisticsTask.isRunning
    );
  }

  get isTableError() {
    return (
      this.getLabelCashflowStatisticsTask.last?.isError ||
      this.getUnlabeledCashflowStatisticsTask.last?.isError
    );
  }

  get isLoading() {
    if (this.abilities.cannot('assign category')) {
      return this.isChartLoading || this.isTableLoading;
    }

    return this.isTimeseriesLoading;
  }

  get isError() {
    if (this.abilities.cannot('assign category')) {
      return this.isChartError || this.isTableError;
    }

    return this.isTimeseriesError;
  }

  get labelStatistics() {
    return this.getLabelCashflowStatisticsTask.last?.value;
  }

  get unlabeledStatistics() {
    return this.getUnlabeledCashflowStatisticsTask.last?.value;
  }

  get maxIndexOffset() {
    return this.maxIndex - this.displayedMonths;
  }

  get isLastPeriod() {
    return this.indexOffset >= this.maxIndexOffset;
  }

  @action onNextMonth() {
    if (this.indexOffset >= this.maxIndexOffset) return;

    this.indexOffset = this.indexOffset + 1;
    this.chartIndexOffset = this.chartIndexOffset + 1;
    this.focusIndex = this.focusIndex - 1;
  }

  get isFirstPeriod() {
    return this.indexOffset === 0;
  }

  get isFirstChartPeriod() {
    return this.indexOffset === 1;
  }

  get showProjectedForecastToggle() {
    return this.abilities.can('view forecast v2 cash-flow') && this.selectedFrequency === 'monthly';
  }

  get isFirstTimeExperience() {
    return (
      this.abilities.cannot('fully interact with forecast cash-flow') &&
      variation('feature--boolean-cash-flow-first-time-experience')
    );
  }

  get firstTimeExperienceDisabledTooltip() {
    if (
      this.cashFlowManager.firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.FORECAST_SET
    ) {
      return this.intl.t('cash-flow.first-time-experience.header-filters.tooltip.free-trial');
    }
    return this.intl.t('cash-flow.first-time-experience.tooltip.free-trial');
  }

  @action onPreviousMonth() {
    if (this.isFirstPeriod) return;

    this.indexOffset = this.indexOffset - 1;
    this.chartIndexOffset = this.chartIndexOffset - 1;
    this.focusIndex = this.focusIndex + 1;
  }

  @action onSelectInterval(interval) {
    // Set the interval index as the first column
    let inflows = this.chartStatistics[0];
    let intervalIndex = inflows.data.display_at_monthly.findIndex(({ start_date }) => {
      return start_date === interval.start;
    });

    let offsetChange = intervalIndex;

    if (this.chartIndexOffset === 0 && offsetChange > 0) return;
    this.indexOffset = offsetChange;
    this.chartIndexOffset = offsetChange - 1;
  }

  get currentDateRelativeIndex() {
    let viewEndIndex = this.indexOffset + this.displayedMonths - 1;

    if (this.currentDateIndex >= this.indexOffset && this.currentDateIndex <= viewEndIndex) {
      return this.currentDateIndex - this.indexOffset + 1;
    }

    return -1;
  }

  @action onResetOffset() {
    this.indexOffset = 0;
  }

  @action onSetFocus(index) {
    this.focusIndex = index;
  }

  @action onFrequencySelectorOpenChange(isOpen) {
    if (isOpen) {
      this.segment.track('cash-flow_period-switcher_opened');
    }
  }

  exportTransactionsTask = dropTask(async () => {
    try {
      let data = {
        organization_id: this.organizationId,
        search: '',
        sort: {
          property: 'emitted_at',
          direction: 'desc',
        },
        export_template_id: 'csv_cash_flow',
        separator: ';',
        with_attachments: false,
      };

      if (this.bankAccounts) {
        data.bank_accounts_ids = this.bankAccounts.split(',');
      }

      await this.networkManager.request(`${apiBaseURL}/v7/transactions/search_export`, {
        method: 'POST',
        data,
      });

      this.toastFlashMessages.toastInfo(this.intl.t('cash-flow.export-transactions.success.toast'));
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action onFrequencyChange(frequency) {
    this._selectedFrequency = frequency;

    if (this.showProjectedForecast && frequency !== 'monthly') {
      this.showProjectedForecast = false;
    }

    this.segment.track('cash-flow_period_selected', {
      property: frequency,
    });
    this.fetchTimeseriesTask.perform(frequency, this.bankAccounts).catch(ignoreCancelation);
  }

  @action onShowProjectedForecastChange(isSelected) {
    this.showProjectedForecast = isSelected;
    this.redrawChart();
  }

  fetchTimeseriesTask = dropTask(async (frequency, bankAccounts) => {
    this.chartStatistics = await this.cashFlowTimeseriesManager.fetchTimeseriesTask.perform({
      frequency,
      bankAccounts,
    });

    this._setControlsState();
  });

  queryStatisticsTask = dropTask(
    async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
      try {
        if (this.abilities.can('assign category')) {
          this.chartStatistics = await this.cashFlowTimeseriesManager.fetchTimeseriesTask.perform({
            frequency: this.selectedFrequency,
            bankAccounts,
          });
        } else {
          await this.getStatisticsTask
            .linked()
            .perform(aggregations, basePeriod, bankAccounts, comparisonPeriod);
        }

        this._setControlsState(basePeriod);

        if (this.abilities.cannot('assign category')) {
          await this.getLabelCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
          await this.getUnlabeledCashflowStatisticsTask.linked().perform(basePeriod, bankAccounts);
        }
      } catch (error) {
        this.captureError(error);
      }
    }
  );

  getStatisticsTask = dropTask(async (aggregations, basePeriod, bankAccounts, comparisonPeriod) => {
    let isPeriod = period => period?.interval || period?.startDate || period?.endDate;

    let getQuery = (period, side) =>
      buildQuery(this.organizationId, aggregations, period, side, bankAccounts);

    let getStatistics = period => [
      this.adapter.getCashflow(getQuery(period, 'credit')),
      this.adapter.getCashflow(getQuery(period, 'debit')),
      this.adapter.getBalance(getQuery(period)),
    ];

    this.chartStatistics = await all(getStatistics(basePeriod));

    if (isPeriod(comparisonPeriod)) {
      this.chartComparisonStatistics = await all(getStatistics(comparisonPeriod));
    }
  });

  getLabelCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getLabelsQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, labelOverTimeAggregations, period, side, bankAccounts)
        );

      return await all([getLabelsQuery('credit'), getLabelsQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  getUnlabeledCashflowStatisticsTask = dropTask(async (period, bankAccounts) => {
    try {
      let getUnlabeledQuery = side =>
        this.adapter.getCashflow(
          buildQuery(this.organizationId, monthlyAggregation, period, side, bankAccounts, {
            property: 'label_list_ids',
            operator: 'not_in',
            values: this.organizationManager.organization.labelLists.map(list => list.id),
          })
        );

      return await all([getUnlabeledQuery('credit'), getUnlabeledQuery('debit')]);
    } catch (error) {
      this.captureError(error);
    }
  });

  handleChartRefresh = dropTask(async () => {
    // Set silent fetch before the task starts
    this.silentFetch = true;

    // Perform the fetch
    this.chartStatistics = await this.cashFlowTimeseriesManager.fetchTimeseriesTask.perform({
      frequency: this._selectedFrequency,
      bankAccounts: [this.bankAccounts],
    });

    this.redrawChart();

    // Disable the silent fetch
    this.silentFetch = false;
  });

  captureError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(errorInfo.error);
    }
  }

  reload() {
    window.location.reload();
  }

  get _chartIndexOffset() {
    // With the new layout introduced in with the cash-flow forecast slice,
    // the number of months displayed in the chart are more than the number of months displayed in the table
    return this.chartIndexOffset;
  }

  _setControlsState(basePeriod) {
    let inflows = this.chartStatistics[0];
    this.maxIndex = inflows.data.display_at_monthly.length;

    this.currentDateIndex = inflows.data.display_at_monthly.findIndex(
      ({ start_date, end_date }) => {
        return dayjs().isBetween(dayjs(start_date), dayjs(end_date), 'day', '[)');
      }
    );

    this.indexOffset = 1;
    this.chartIndexOffset = 0;

    this.currentDateOffset = this.displayedMonths - this.numberOfColumns;

    this.indexOffset = this.currentDateIndex - this.currentDateOffset;
    this.indexOffset = this.indexOffset - 1; // always show currentDate as the second column
    this.chartIndexOffset = this.indexOffset - 1;

    if (basePeriod) {
      this.selectedPeriod = basePeriod;
    }
  }
}
