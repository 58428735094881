export const paymentLinksCreation = {
  steps: {
    'products-and-services': {
      componentName: 'payment-links/creation/products-and-services',
      nextStepId: 'payment-methods',
    },
    'payment-methods': {
      componentName: 'payment-links/creation/payment-methods',
      nextStepId: 'settings',
    },
    settings: {
      componentName: 'payment-links/creation/settings',
      nextStepId: 'success',
    },
    success: {
      componentName: 'payment-links/creation/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
