export default {
  "container": "qNy",
  "form-container": "qNU",
  "preview-container": "qNj",
  "credit-note-preview-container": "qNW",
  "credit-note-preview": "qNu",
  "content": "qvS",
  "title": "qvc",
  "tabs-container": "qvq",
  "close-button": "qvZ btn btn--icon-only btn--tertiary btn--large",
  "header": "qvR",
  "without-tabs": "qvQ"
};
