export default {
  "container": "qiu",
  "wrapper": "qAS",
  "grey-header-block": "qAc",
  "connect-app-header": "qAq",
  "connect-app-header-link": "qAZ",
  "connect-disclaimer-wrapper": "qAR",
  "connect-app-header-content": "qAQ",
  "connect-app-header-cta": "qAe",
  "connect-app-content": "qAB",
  "connect-app-description": "qAE",
  "connect-app-body": "qAd"
};
