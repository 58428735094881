export default {
  "page-wrapper": "qTq",
  "back-button": "qTZ",
  "abort-button": "qTR",
  "container": "qTQ",
  "subtitle": "qTe",
  "button": "qTB",
  "panel-list": "qTE",
  "panel-list-item": "qTd",
  "card-illustration": "qTI"
};
