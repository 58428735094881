export default {
  "container": "qiN",
  "link-grey": "qiv",
  "slack-section-title": "qio title-4 mb-8",
  "edit-channel-text": "qin body-2 mb-16",
  "rule-section-title": "qii title-4 mb-16",
  "rule-cards-container": "qiA",
  "edit-slack-container": "qiY",
  "rule-card": "qih",
  "add-rule-container": "qif",
  "add-rule-link-button": "qiK",
  "add-rule-text": "qiG",
  "empty-state": "qir",
  "empty-state-textblock": "qib",
  "empty-state-title": "qiy title-2",
  "empty-state-description": "qiU body-2",
  "empty-state-illustration": "qij",
  "rules": "qiW"
};
