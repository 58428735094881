import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { TrackedObject } from 'tracked-built-ins';

import { CATEGORIES } from 'qonto/constants/connect';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

class InvoicesClientsImportFlowDataContext {
  nextStepId = null;
  uploadedFiles = [];
  xlsFile = null;
  mapping = new TrackedObject({});
  integrations = [];
  status = { isMapped: false };
}

export default class InvoicesClientsImportFlowSetup extends FlowSetup {
  @service router;
  @service connectManager;
  @service flow;

  constructor() {
    super(...arguments);

    this.dataContext = new InvoicesClientsImportFlowDataContext();
  }

  loadIntegrationsTask = dropTask(async () => {
    try {
      if (variation('feature--boolean-qontent-connect-hub')) {
        let { connect_integrations } = await this.connectManager.getIntegrationsTask
          .perform({
            categorySlug: CATEGORIES.AR_CLIENTS_MIGRATION,
          })
          .catch(ignoreCancelation);

        this.dataContext.integrations = connect_integrations;
      } else {
        let categories = await this.connectManager.getAllCategories();
        let arClientsMigrationCategory = categories.find(
          category => category.slug === CATEGORIES.AR_CLIENTS_MIGRATION
        );

        if (arClientsMigrationCategory) {
          let { results } = await this.connectManager.getIntegrationsTask
            .perform({
              categoryId: arClientsMigrationCategory.id,
            })
            .catch(ignoreCancelation);

          this.dataContext.integrations = results;
        }
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      throw error;
    }
  });

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'accounts-receivable');

    await this.loadIntegrationsTask.perform().catch(ignoreCancelation);
  }

  @action
  onComplete() {
    this._goBackToMainPage();
  }

  @action
  onAbort() {
    this._goBackToMainPage();
  }

  _goBackToMainPage() {
    if (this.flow.refererPage && this.flow.refererPage !== '/') {
      return this.router.transitionTo(this.flow.refererPage);
    }
    return this.router.replaceWith('clients.index');
  }
}
