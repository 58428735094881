import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FreemiumUpgradeModaleRoute extends Route {
  @service menu;
  @service homePage;
  @service upsellManager;

  beforeModel() {
    if (!this.upsellManager.shouldShowFreemiumUpgrade) {
      this.homePage.replaceWithDefaultPage();
    }
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
