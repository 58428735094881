export default {
  "header": "qiR",
  "content": "qiQ",
  "read-only": "qie",
  "disclaimer": "qiB",
  "title": "qiE",
  "card-container": "qid",
  "representatives-section": "qiI",
  "disabled-href": "qit"
};
