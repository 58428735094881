import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  APPROVAL_WORKFLOW_PROMOTION_STORAGE_KEY,
  REQUEST_TYPES,
} from 'qonto/constants/approval-workflow';
import CURRENCIES from 'qonto/constants/currencies';
import { CONTRACT_STATUS, PAY_LATER_ELIGIBILITY_STATUSES } from 'qonto/constants/financing';
import {
  DISCLAIMER_TYPES,
  OPERATION_TYPES,
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  STATUS,
  TRANSFER_FLOW_EXIT_ROUTE,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { getCurrentParisDate } from 'qonto/utils/date';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { getNRCIban } from 'qonto/utils/nrc';
import {
  copyBeneficiaryIntoTransfer,
  copyBeneficiaryLabelsIntoTransfer,
  copyBeneficiaryVatIntoTransfer,
  createSepaSettlementTrackingEventPayload,
} from 'qonto/utils/transfers';

class SepaTransferFlowDataContext {
  sepaTransfer = null;
  beneficiary = null;
  invoice = null;
  origin = null;
  supplierInvoiceId = null;
  repeatedTransferId = null;
  payLaterTransferId = null;
  nrcOptions = null;
  isInstantFallback = false;
  confirmationResult = {};
  settlementResult = {};
  budgetName = null;
  availableCreditAmount = null;
  isPayLaterEligible = null;
  isPayLaterContractSigned = null;
  payLaterInstallments = null;
  signature = { url: '', status: '' };
  defaultOperationType = OPERATION_TYPES.SCHEDULED;
  payLaterErrors = [];
  didValidateFinancing = false;
  didInstallmentsFail = false;
  minTransferAmount = null;
  invoiceAttachmentLimits = null;
  isPayLater = false;
  exitRoute = null;

  constructor({
    sepaTransfer,
    invoice,
    origin,
    beneficiary = null,
    supplierInvoiceId,
    nrcOptions = {},
    repeatedTransferId = null,
    payLaterTransferId = null,
    kycSubmitted,
    showApprovalWorkflowPromotion = false,
    exitRoute = null,
  }) {
    this.sepaTransfer = sepaTransfer;
    this.invoice = invoice;
    this.origin = origin;
    this.beneficiary = beneficiary;
    this.supplierInvoiceId = supplierInvoiceId;
    this.payLaterTransferId = payLaterTransferId;
    this.nrcOptions = nrcOptions;
    this.repeatedTransferId = repeatedTransferId;
    this.kycSubmitted = kycSubmitted;
    this.showApprovalWorkflowPromotion = showApprovalWorkflowPromotion;
    this.exitRoute = exitRoute;
  }
}

export default class SepaTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service attachmentsManager;
  @service beneficiariesManager;
  @service errors;
  @service flow;
  @service flowLinkManager;
  @service financing;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service payByInvoiceUploadManager;
  @service router;
  @service segment;
  @service store;
  @service toastFlashMessages;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { accounts, organization } = this.organizationManager;
    let { defaultAccount } = organization;
    let { params: routeParams, queryParams } = this.router.currentRoute;
    let {
      amount,
      companyType,
      origin,
      reference,
      supplierInvoiceId,
      taxBeneficiaryName,
      taxModel,
      payLaterTransferId,
      repeatedTransferId,
      bankAccountId,
      exitRoute,
    } = queryParams;
    let { beneficiary, invoice, sepaTransfer } = previousDataContext;

    let bankAccount = bankAccountId && accounts.find(account => account.id === bankAccountId);

    let sepaTransferRecord;

    if (origin === TRANSFER_FLOW_ORIGIN.QONTO_PILOT) {
      let draftSepaTransfer = this.store
        .peekAll('transfer')
        .filter(transfer => transfer.isNew && !transfer.id);
      sepaTransferRecord = draftSepaTransfer.length ? draftSepaTransfer[0] : null;
    }

    if (origin === TRANSFER_FLOW_ORIGIN.PAY_LATER_FLOW && payLaterTransferId) {
      let transferRecord = this.store
        .peekAll('transfer')
        .find(t => t.idempotencyKey === payLaterTransferId);

      if (transferRecord) {
        beneficiary = transferRecord.get('beneficiary');
      }
    }

    if (!sepaTransferRecord) {
      sepaTransferRecord =
        sepaTransfer ||
        this.store.createRecord('transfer', {
          activityTag: 'other_expense',
          amountCurrency: CURRENCIES.default,
          bankAccount: bankAccount || defaultAccount,
          fx: false,
          organization,
        });
    }

    sepaTransferRecord.addIdempotencyKey();

    this.dataContext = new SepaTransferFlowDataContext({
      ...(beneficiary && { beneficiary }),
      sepaTransfer: sepaTransferRecord,
      invoice,
      origin,
      supplierInvoiceId,
      payLaterTransferId,
      repeatedTransferId,
      exitRoute,
      nrcOptions: {
        amount,
        companyType,
        reference,
        taxBeneficiaryName,
        taxModel,
      },
      kycSubmitted: this.organizationManager.membership.kycSubmitted,
      showApprovalWorkflowPromotion:
        origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES &&
        this.shouldShowApprovalWorkflowPromotion(sepaTransferRecord),
    });

    if (this.dataContext.beneficiary) {
      this._setTransferBeneficiary(this.dataContext.beneficiary);
    }

    if (origin === TRANSFER_FLOW_ORIGIN.PAY_LATER_FLOW) {
      this.dataContext.isPayLater = true;
    }

    if (routeParams.name === 'sepa-transfer') {
      let startingPage =
        variation('feature--boolean-improve-invoice-payment-flows') &&
        origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES
          ? 'invoices-list'
          : routeParams.step_id;
      this.segment.track('transfer-sepa_flow_started', {
        starting_page: startingPage,
        ...(origin && { origin }),
        ...(supplierInvoiceId && {
          is_einvoice: this.isEInvoice(supplierInvoiceId),
        }),
      });
    }
  }

  async beforeFlow({ stepId } = {}) {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create transfer')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let { origin, sepaTransfer, supplierInvoiceId } = this.dataContext;

    if (origin) {
      await this._prefillTransferOptions(origin);
    }

    if (
      (origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES ||
        origin === TRANSFER_FLOW_ORIGIN.UPCOMING_TRANSACTIONS) &&
      supplierInvoiceId
    ) {
      await this.prefillSupplierInvoiceOptionsTask
        .perform(sepaTransfer, supplierInvoiceId)
        .catch(ignoreCancelation);
    }

    let stepToRestart = this._getStepToRestart(stepId, this.dataContext);
    if (stepToRestart) {
      return this.flowLinkManager.transitionTo({ name: 'sepa-transfer', stepId: stepToRestart });
    }

    if (this.abilities.can('view pay later toggle in financing')) {
      try {
        let {
          eligibility,
          contractStatus,
          availableCreditAmount,
          minTransferAmount,
          invoiceAttachmentLimits,
        } = await this.financing.checkPayLaterEligibility();

        Object.assign(this.dataContext, {
          availableCreditAmount,
          isPayLaterContractSigned: contractStatus === CONTRACT_STATUS.SIGNED,
          isPayLaterEligible: eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE,
          minTransferAmount,
          invoiceAttachmentLimits,
        });
      } catch (error) {
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  }

  organisationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  isEInvoice(invoiceId) {
    let invoice = this.store.peekRecord('supplier-invoice', invoiceId);
    return invoice?.isElectronicInvoice ?? false;
  }

  /**
   * This method checks if the approval workflow promotion should be shown on the settlement step for the given SEPA transfer.
   * The promotion is shown if the following conditions are met:
   * - The user has the ability to create approval workflows
   * - The organization is not KYB pending
   * - The user is not KYC pending
   * - The transfer beneficiary is not a Qonto beneficiary
   * - The transfer is not a pay later transfer
   * - The user has not seen the approval workflow promotion yet
   * - The organization have not setup any approval workflow for supplier invoices
   */
  shouldShowApprovalWorkflowPromotion(sepaTransfer) {
    let { organization, membership } = this.organizationManager;
    let isKybPending = organization.kybPending;
    let isKycPending = membership.kycPending;
    let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');
    let operationType = sepaTransfer.get('operationType');

    let preconditionsEnabled =
      this.abilities.can('create approval-workflow') &&
      !isKybPending &&
      !isKycPending &&
      !isQontoBeneficiary &&
      operationType !== OPERATION_TYPES.PAY_LATER &&
      !this.hasSeenApprovalWorkflowPromotion;

    if (preconditionsEnabled) {
      let hasDefaultSupplierInvoiceApprovalWorkflow = this.store
        .peekAll('approval-workflow')
        // @ts-expect-error
        .some(({ requestType, rulesets }) => {
          return requestType === REQUEST_TYPES.SUPPLIER_INVOICE && rulesets.length === 0;
        });

      return hasDefaultSupplierInvoiceApprovalWorkflow;
    }

    return false;
  }

  get hasSeenApprovalWorkflowPromotion() {
    return safeLocalStorage.getItem(APPROVAL_WORKFLOW_PROMOTION_STORAGE_KEY);
  }

  @action
  onComplete({
    confirmationResult,
    invoice,
    origin,
    sepaTransfer,
    transferRequest,
    supplierInvoiceId,
    settlementResult,
  }) {
    let { status, instant, isDeclined, isProcessing, isCompleted } = sepaTransfer;
    let { isTimeout, declinedReason } = settlementResult;
    let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');
    let hasQontoBeneficiaryWarning = confirmationResult?.warnings?.includes(
      DISCLAIMER_TYPES.QONTO_BANK_ACCOUNT
    );

    let eventName;

    if (!instant || isCompleted) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED;
    } else if (isTimeout) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.TIMEOUT_CLOSED;
    } else if (isDeclined) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.REJECT_CLOSED;
    } else if (isProcessing) {
      eventName = SEPA_TRACKING_SETTLEMENT_EVENTS.LOADING_CLOSED;
    }

    let payload = createSepaSettlementTrackingEventPayload({
      invoice,
      origin,
      sepaTransfer,
      isTimeout,
      isRequest: Boolean(transferRequest),
      declinedReason,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.EXIT,
      isQontoBeneficiary,
      isKYCPending: this.organizationManager.membership.kycPending,
    });

    this.segment.track(eventName, payload);

    this.payByInvoiceUploadManager.resetState();

    if (
      [TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER, TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT].includes(
        origin
      ) &&
      this.flow.refererPage
    ) {
      return this.router.transitionTo(this.flow.refererPage);
    }

    if (
      (instant && [STATUS.COMPLETED, STATUS.DECLINED].includes(status)) ||
      hasQontoBeneficiaryWarning
    ) {
      return this._redirect({
        url: 'transfers.past',
        origin,
        sepaTransfer,
        supplierInvoiceId,
      });
    }

    return this._redirect({
      url: 'transfers.pending',
      origin,
      sepaTransfer,
      supplierInvoiceId,
    });
  }

  onAbortTask = dropTask(
    async (
      { isInstantFallback, origin, sepaTransfer, supplierInvoiceId, exitRoute },
      { id: stepId }
    ) => {
      let shouldConfirmAbort = !['beneficiaries', 'invoice-upload'].includes(stepId);

      if (shouldConfirmAbort) {
        let result = await this.openAbortModalTask.perform();
        if (result !== 'confirm') return false;
      }

      this.payByInvoiceUploadManager.resetState();

      if (supplierInvoiceId && origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
        let supplierInvoice = this.store.peekRecord('supplier-invoice', supplierInvoiceId);
        if (supplierInvoice && supplierInvoice.hasDirtyAttributes) {
          supplierInvoice.rollbackAttributes();
        }
      }

      this._trackOnAbort({ isInstantFallback, origin, stepId });

      if ([TRANSFER_FLOW_ORIGIN.QONTO_PILOT].includes(origin)) {
        this.store.unloadRecord(this.dataContext.sepaTransfer);
      }

      if (
        ([
          TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
          TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT,
          TRANSFER_FLOW_ORIGIN.OVERVIEW,
        ].includes(origin) ||
          exitRoute === TRANSFER_FLOW_EXIT_ROUTE.TASKS) &&
        this.flow.refererPage
      ) {
        return this.router.transitionTo(this.flow.refererPage);
      }

      this._redirect({ url: 'transfers.landing', origin, sepaTransfer, supplierInvoiceId });

      return true;
    }
  );

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      })
  );

  prefillNRCOptionsTask = dropTask(async sepaTransfer => {
    let { amount, companyType, reference, taxBeneficiaryName, taxModel } =
      this.dataContext.nrcOptions;

    let iban = getNRCIban(taxBeneficiaryName);
    if (iban) {
      let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
        this.organizationManager.organization.id,
        iban
      );
      if (matchingBeneficiary) this._setTransferBeneficiary(matchingBeneficiary);
    }

    sepaTransfer.setProperties({
      amount,
      activityTag: 'tax',
      note: `${amount}+${taxModel}+${companyType}+${reference}`,
      reference,
    });
  });

  prefillSupplierInvoiceOptionsTask = dropTask(async (sepaTransfer, supplierInvoiceId) => {
    this.dataContext.invoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
    let supplierInvoice = this.dataContext.invoice;
    if (supplierInvoice) {
      let attachment = await supplierInvoice.belongsTo('attachment').load();
      if (attachment) {
        let { iban: legacyIban, invoiceNumber, totalAmount, supplierSnapshot } = supplierInvoice;

        let iban = supplierSnapshot?.iban || legacyIban;

        if (iban) {
          let matchingBeneficiary = await this.beneficiariesManager.getSEPABeneficiaryByIban(
            this.organizationManager.organization.id,
            iban
          );
          if (matchingBeneficiary) {
            this._setTransferBeneficiary(matchingBeneficiary);
            supplierInvoice.set('beneficiary', matchingBeneficiary);

            this.dataContext.beneficiary = matchingBeneficiary;

            if (this.dataContext.origin === TRANSFER_FLOW_ORIGIN.UPCOMING_TRANSACTIONS) {
              let mainAccount = this.organizationManager.organization.mainAccount;
              if (mainAccount) {
                sepaTransfer.set('bankAccount', mainAccount);
              }
            }
          }
        }

        let reference = invoiceNumber ?? undefined;
        if (variation('feature--boolean-improve-invoice-payment-flows')) {
          reference = this.intl.t('transfers.pay-by-invoice.payment-details.reference.value', {
            invoiceNumber,
          });
        }
        sepaTransfer.setProperties({
          amount: totalAmount ? totalAmount.value : null,
          attachments: attachment ? [attachment] : undefined,
          reference,
        });
      }
    }
  });

  prefillRepeatTransferTask = dropTask(async (sepaTransfer, repeatedTransferId) => {
    let repeatedTransfer = await this.store.findRecord('transfer', repeatedTransferId);
    let { reference, notifyByEmail, accountNumber, email, note, vatRate, purpose } =
      repeatedTransfer;
    let beneficiary = await repeatedTransfer.get('beneficiary');

    if (!beneficiary || beneficiary.hidden) {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
      this.router.transitionTo('transfers.pending');
    } else {
      this._setTransferBeneficiary(beneficiary);
      sepaTransfer.setProperties({
        reference,
        notifyByEmail,
        accountNumber,
        email,
        note,
        vatRate,
        purpose,
      });
      let targetBankAccount = await repeatedTransfer.get('bankAccount');
      if (targetBankAccount.isActive) {
        sepaTransfer.set('bankAccount', repeatedTransfer.get('bankAccount'));
      }
      sepaTransfer.set('labels', repeatedTransfer.get('labels'));
    }
  });

  prefillPayLaterTransferTask = dropTask(async (sepaTransfer, payLaterTransferId) => {
    let transferRecord = await this.store
      .peekAll('transfer')
      .find(t => t.idempotencyKey === payLaterTransferId);

    if (!transferRecord) return;

    let { reference, status, amountCurrency, amount, localAmount, operationType } = transferRecord;

    let beneficiary = await transferRecord.get('beneficiary');

    if (beneficiary || !beneficiary.hidden) {
      this._setTransferBeneficiary(beneficiary);

      sepaTransfer.setProperties({
        reference,
        status,
        amountCurrency,
        amount,
        localAmount,
        operationType,
      });
    }
  });

  async _prefillTransferOptions(origin) {
    if (origin === TRANSFER_FLOW_ORIGIN.NRC) {
      await this.prefillNRCOptionsTask.perform(this.dataContext.sepaTransfer);
    }

    if (origin === TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER) {
      await this.prefillRepeatTransferTask.perform(
        this.dataContext.sepaTransfer,
        this.dataContext.repeatedTransferId
      );
    }

    if (origin === TRANSFER_FLOW_ORIGIN.PAY_LATER_FLOW) {
      await this.prefillPayLaterTransferTask.perform(
        this.dataContext.sepaTransfer,
        this.dataContext.payLaterTransferId
      );
    }
  }

  _redirect({ url, origin, sepaTransfer, supplierInvoiceId }) {
    if (origin && supplierInvoiceId) {
      return this.router.transitionTo(origin, {
        queryParams: {
          transferStatus: sepaTransfer.status,
          supplierInvoiceId,
        },
      });
    }

    return this.router.transitionTo(url);
  }

  _resetTransferDetails(transfer) {
    transfer.setProperties({
      amount: null,
      reference: null,
      notifyByEmail: false,
      email: null,
      operationType: OPERATION_TYPES.SCHEDULED,
      scheduleDate: getCurrentParisDate(),
    });
  }

  _setTransferBeneficiary(beneficiary) {
    let { invoice, sepaTransfer } = this.dataContext;

    // The reset of transfer details if the current beneficiary des not match the previous one
    // is only needed if we are not using an invoice to prefill them.
    if (!invoice) {
      let currentBeneficiaryId = sepaTransfer.get('beneficiary.id');
      if (beneficiary.id !== currentBeneficiaryId) this._resetTransferDetails(sepaTransfer);
    }

    sepaTransfer.set('beneficiary', beneficiary);

    copyBeneficiaryIntoTransfer(sepaTransfer, beneficiary, { forceCopy: true });
    copyBeneficiaryLabelsIntoTransfer(sepaTransfer, beneficiary);
    if (this.abilities.can('view vat bookkeeping')) {
      copyBeneficiaryVatIntoTransfer(sepaTransfer, beneficiary);
    }
  }

  _trackOnAbort({ isInstantFallback, origin, stepId }) {
    let trackingEventName =
      stepId === 'summary' && isInstantFallback
        ? 'transfer-sepa_fallback_summary_closed'
        : 'transfer_creation_exited';

    this.segment.track(trackingEventName, {
      ...(origin && { origin }),
    });
  }

  _getStepToRestart(stepId, dataContext) {
    let { beneficiary, invoice, origin } = dataContext;
    let isSpecialOrigin = [
      TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
      TRANSFER_FLOW_ORIGIN.NRC,
      TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES,
      TRANSFER_FLOW_ORIGIN.QONTO_PILOT,
      TRANSFER_FLOW_ORIGIN.UPCOMING_TRANSACTIONS,
    ].includes(origin);

    if (['details', 'additional-settings', 'summary'].includes(stepId) && !beneficiary) {
      if (origin === TRANSFER_FLOW_ORIGIN.UPCOMING_TRANSACTIONS) return 'invoice';
      return stepId === 'details' && isSpecialOrigin ? null : 'beneficiaries';
    }

    if (stepId === 'invoice' && !invoice) {
      return 'invoice-upload';
    }

    return null;
  }
}
