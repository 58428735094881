export default {
  "container": "qYN",
  "security-method": "qYv",
  "icon": "qYo",
  "body": "qYn",
  "status": "qYi body-2",
  "device": "qYA",
  "device-icon": "qYY",
  "subtitle": "qYh body-2",
  "current": "qYf"
};
