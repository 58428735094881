import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class SupplierInvoicesIndexRoute extends Route {
  @service router;
  @service supplierInvoicesManager;

  async model() {
    let isFirstTimeUsage =
      !variation('feature--boolean-disable-supplier-invoices-first-time-experience') &&
      (await this.supplierInvoicesManager.fetchFirstTimeUsageTask.perform());

    return {
      isFirstTimeUsage,
    };
  }

  afterModel({ isFirstTimeUsage }) {
    if (isFirstTimeUsage) {
      return this.router.replaceWith('supplier-invoices.intro');
    } else {
      return this.router.replaceWith('supplier-invoices.list');
    }
  }
}
