export default {
  "container": "qAP",
  "content": "qAl",
  "setup-box": "qAX",
  "setup-wizard": "qAC",
  "setup-success": "qAk",
  "setup-success-lottie": "qAJ",
  "setup-close-btn": "qAs",
  "tray-connection": "qAF",
  "close-button": "qAg"
};
